<template>
  <div class="contact">
    <section :style="{ backgroundImage: 'url(' + require('../../public/assets/i/pageTitle.jpg') + ')' }"
             class="wrapper image-wrapper bg-image bg-overlay bg-overlay-400 text-white px-0 mt-0">
      <div class="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <h1 class="display-1 mb-3 text-white">{{ langcontent.contact }}</h1>
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ol class="breadcrumb text-white">
                <li class="breadcrumb-item">
                  <router-link to="/">{{ langcontent.home }}</router-link>
                </li>
                <li aria-current="page" class="breadcrumb-item active">{{ langcontent.contact }}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <section class="wrapper bg-light angled upper-end">
      <div class="container pb-11">
        <div class="row mb-14 mb-md-16">
          <div class="col-xl-10 mx-auto mt-n19">
            <div class="card">
              <div class="row gx-0">
                <div class="col-lg-6 align-self-stretch">
                  <div class="map map-full rounded-top rounded-lg-start">
                    <iframe :src="contact.maps" allowfullscreen style="width:100%; height: 100%; border:0"></iframe>
                    <!-- End Google Map -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="p-10 p-md-11 p-lg-14">
                    <div class="d-flex flex-row">
                      <div>
                        <div class="icon text-primary fs-28 me-4 mt-n1"><i class="uil uil-location-pin-alt"></i></div>
                      </div>
                      <div class="align-self-start justify-content-start">
                        <h5 class="mb-1">{{ langcontent.addr }}</h5>
                        <address>
                          {{ contact.addr }}
                        </address>
                      </div>
                    </div>
                    <div class="d-flex flex-row">
                      <div>
                        <div class="icon text-primary fs-28 me-4 mt-n1"><i class="uil uil-phone-volume"></i></div>
                      </div>
                      <div>
                        <h5 class="mb-1">{{ langcontent.phone }}</h5>
                        <p><a :href="'tel:'+contact.phone">{{ contact.phone }}</a></p>
                      </div>
                    </div>
                    <div class="d-flex flex-row">
                      <div>
                        <div class="icon text-primary fs-28 me-4 mt-n1"><i class="uil uil-envelope"></i></div>
                      </div>
                      <div>
                        <h5 class="mb-1">{{ langcontent.email }}</h5>
                        <p class="mb-0">
                          <a :href="'mailto:'+contact.mail" class="link-body"><span>{{ contact.mail }}</span></a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <h2 class="display-4 mb-3 text-center">{{ langcontent.contactus }}</h2>
            <form class="contact-form needs-validation" method="post" novalidate>
              <div class="row gx-4">
                <div class="col-md-6">
                  <div class="form-floating mb-4">
                    <input id="form_name"
                           :class="{ error: v$.name.$errors.length }"
                           v-model="name" :placeholder="langcontent.name" @input="setName" class="form-control" required
                           type="text">
                    <label for="form_name">{{ langcontent.name }} *</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mb-4">
                    <input id="form_lastname"
                           :class="{ error: v$.surname.$errors.length }"
                           v-model="surname" :placeholder="langcontent.surname" @input="setSurname" class="form-control"
                           required type="text">
                    <label for="form_lastname">{{ langcontent.surname }} *</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mb-4">
                    <input id="form_email"
                           :class="{ error: v$.mail.$errors.length }"
                           v-model="mail" :placeholder="langcontent.mail" @input="setMail"  class="form-control" required
                           type="email">
                    <label for="form_email">{{ langcontent.mail }} *</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mb-4">
                    <input id="form_phone"
                           :class="{ error: v$.phone.$errors.length }"
                           v-model="phone" :placeholder="langcontent.tel" @input="setPhone"  class="form-control" required
                           type="number">
                    <label for="form_email">{{ langcontent.tel }} *</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-floating mb-4">
                    <textarea id="form_message"
                              :class="{ error: v$.desc.$errors.length }"
                              v-model="desc" class="form-control" @input="setDesc"  placeholder="Your message" required
                              style="height: 150px"></textarea>
                    <label>{{ langcontent.message }}... *</label>
                  </div>
                </div>
                <div class="col-12">
                  <a class="btn btn-primary rounded-pill btn-send mb-3 " @click="post"><span>{{
                      langcontent.send
                    }}</span></a>
                  <p class="text-muted">* {{ langcontent.formerr }} </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <div :class="{'acc': popupText}" class="popup" @click="popup">
      <div class="content">
        <i>
          <svg class="feather feather-x" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
               stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
               xmlns="http://www.w3.org/2000/svg">
            <line x1="18" x2="6" y1="6" y2="18"></line>
            <line x1="6" x2="18" y1="6" y2="18"></line>
          </svg>
        </i>
        <p>{{ popupText }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import iso from "../axios";
import axios from "axios";
import useVuelidate from '@vuelidate/core'
import {email, required, minLength} from '@vuelidate/validators'

export default {
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      contact: [],
      langcontent: [],
      name: "",
      surname: "",
      mail: "",
      phone: "",
      desc: "",
      popupText: null
    }
  },
  validations() {
    return {
      name: {required},
      surname: {required},
      mail: {required,email},
      phone: {required,minLength: minLength(10)},
      desc: {required},
    }
  },
  created() {
    iso.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
          document.title = 'Bimark | ' + this.langcontent.contact;
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('contact')
        .then(response => {
          this.contact = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  methods: {
    setName ($event) {
      this.name = $event.target.value.
      this.v$.name.$touch()
    },
    setSurname ($event) {
      this.surname = $event.target.value
      this.v$.surname.$touch()
    },
    setMail ($event) {
      this.mail = $event.target.value.toLowerCase()
      this.v$.mail.$touch()
    },
    setPhone ($event) {
      this.phone = $event.target.value.toUpperCase()
      this.v$.phone.$touch()
    },
    setDesc ($event) {
      this.desc = $event.target.value
      this.v$.desc.$touch()
    },
    async post () {
      const result = await this.v$.$validate()
      console.log(this.v$.$validate())
      if (!result) {
        return
      }
      const data = new FormData();
      // data.append('dil', localStorage.getItem('lang'))
      data.append('name', this.name)
      data.append('surname', this.surname)
      data.append('mail', this.mail)
      data.append('tel', this.phone)
      data.append('desc', this.desc)

      axios.post('https://yonet.gifadwork.com/apipost/bimark/form', data)
          .then(response => {
            this.popupText = this.langcontent.succesform
            this.name = null
            this.surname = null
            this.mail = null
            this.phone = null
            this.desc = null
            this.err = null
            window.scrollTo(0, 0);
          })
          .catch(error => console.log(error))
    },

    popup: function () {
      if (this.popupText != null) {
        this.popupText = null
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
<style>
input.error{
  color: #ff0000!important;
  background-color: rgba(255, 0, 0, 0.05) !important;
  border: 1px solid rgb(255, 0, 0) !important;
  box-shadow: 0 0 1.25rem rgba(255, 0, 0, 0.03)!important;
}textarea.error{
   color: #ff0000!important;
   background-color: rgba(255, 0, 0, 0.05) !important;
   border: 1px solid rgb(255, 0, 0) !important;
   box-shadow: 0 0 1.25rem rgba(255, 0, 0, 0.03)!important;
 }

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fffd;
  width: 100vw;
  height: 100vh;
}

.popup.acc {
  display: block;
}

.popup .content {
  display: block;
  position: relative;
  width: 300px;
  left: calc(50% - 150px);
  background-color: #fff;
  top: 50%;
  padding: 20px;
  padding-top: 35px;
  box-shadow: rgb(93 23 23 / 25%) 0px 25px 50px -12px;
}

.popup .content i {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>